import { ContentfulTeamMember } from '../../model/generated/graphql';
import React, { FC } from 'react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { pxToRem } from '../../utils/pxToRem';

export type MemberProps = {
    member: ContentfulTeamMember;
};
const lorem =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

export const Member: FC<MemberProps> = ({ member }) => {
    const image = member.avatar ? member.avatar.gatsbyImageData : '../images/batman-comic.png';
    return (
        <StyledMember>
            {member.avatar && member.avatar.gatsbyImageData ? (
                <Avatar alt="" image={image} />
            ) : (
                <StaticImage
                    src="../images/batman-comic.png"
                    alt=""
                    placeholder="blurred"
                    layout="fixed"
                    width={200}
                    height={200}
                />
            )}
            <h2>{member.name}</h2>

            {member.bio ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: member.bio?.childMarkdownRemark?.html,
                    }}
                />
            ) : (
                <p>{lorem}</p>
            )}
        </StyledMember>
    );
};

const StyledMember = styled.div`
    width: ${pxToRem(360)};
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: justify;
    margin-bottom: 6rem;
`;

export const Avatar = styled(GatsbyImage)`
    border-radius: 50%;
`;
