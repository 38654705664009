import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { pxToRem } from '../utils/pxToRem';

import { ContentfulTeam, ContentfulTeamMember, Maybe } from '../model/generated/graphql';
import { Member } from '../modules/crew/Member';
import { Container, ProjectSectionTitle } from '../modules/movies/components/styles';

type TeamPageProps = {
    data: {
        contentfulTeam: ContentfulTeam;
    };
};

const StyledSection = styled.div`
    display: flex;
    gap: 7rem;
    justify-content: center;
    flex-wrap: wrap;
`;

export const PageTitle = styled.h1`
  font-size: ${pxToRem(60)};
  text-align: center;
  margin-bottom: 8rem};
`;

const renderMembers = (members: Maybe<ContentfulTeamMember>[], width?: string) => {
    return (
        // <Container style={{ width: width && width }}>
        <StyledSection>
            {members.map((item) => {
                return item && <Member member={item} key={item.id} />;
            })}
        </StyledSection>
        // </Container>
    );
};

const CrewPage: FC<TeamPageProps & PageProps> = ({ data, location }) => {
    const team = data.contentfulTeam;

    const roles = {
        owners: [],
        production: [],
        artists: [],
        authors: [],
    };

    team.members &&
        team.members.forEach((item) => {
            if (item) {
                switch (item.role) {
                    case 'Co-owners & Producers':
                        return roles.owners.push(item);
                    case 'Production team':
                        return roles.production.push(item);
                    case 'Artists':
                        return roles.artists.push(item);
                    case 'Authors':
                        return roles.authors.push(item);
                }
            }
        });

    return (
        <Container maxWidth={1440}>
            <ProjectSectionTitle>The Crew</ProjectSectionTitle>
            <Title>Co-owners & Producers</Title>
            {renderMembers(roles.owners)}

            <Title>Production team</Title>
            {renderMembers(roles.production)}

            <Title>Artists</Title>
            {renderMembers(roles.artists)}

            <Title>Authors</Title>
            {renderMembers(roles.authors, '80%')}
        </Container>
    );
};

export default CrewPage;

const Title = styled.h2`
    text-align: center;
    color: var(--color-text-primary);
    z-index: 1;
    font-size: 3rem;
    margin-top: 0;
    @media (max-width: ${pxToRem(1440)}) {
        font-size: 2.5rem;
    }
    @media (max-width: ${pxToRem(1080)}) {
        font-size: 2rem;
    }
`;

export const pageQuery = graphql`
    query CrewQuery($node_locale: String) {
        contentfulTeam(node_locale: { eq: $node_locale }, contentful_id: { eq: "61i2oJNtIqHuQAeT4ywCni" }) {
            title
            members {
                id
                name
                role
                avatar {
                    gatsbyImageData(width: 200, height: 200, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
                bio {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
`;
