import styled from 'styled-components';
import { pxToRem } from '../../../utils/pxToRem';

export const ProjectSectionTitle = styled.h2`
    color: var(--color-text-primary);
    z-index: 1;
    font-size: 5rem;
    margin-top: 0;
    @media (max-width: ${pxToRem(1440)}) {
        font-size: 4rem;
    }
    @media (max-width: ${pxToRem(1080)}) {
        font-size: 3rem;
    }
`;

type ContainerProps = {
    maxWidth: number;
};
export const Container = styled.div<ContainerProps>`
    max-width: ${(props) => (props.maxWidth ? pxToRem(props.maxWidth) : pxToRem(1440))};
    margin: 0 auto;
    z-index: 1;
    position: relative;

    ${ProjectSectionTitle} {
        margin-top: 6rem;
        margin-bottom: 2.5rem;
        text-align: center;
        @media (max-width: 600px) {
            margin-top: 0;
        }
    }
`;
